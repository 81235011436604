import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/filter'
import { VueAxios } from './utils/request'

import ViewUI, { Message } from 'view-design'
import 'view-design/dist/styles/iview.css'
import './assets/styles/iview.less'

import './assets/styles/base.scss'

import { get } from '@/utils/util'

import PageView from '@/components/PageView'
import CardBox from '@/components/Card'
import SelectDict from '@/components/SelectDict'
import InputNumber from '@/components/InputNumber'
import Empty from '@/components/Empty'
import Image from '@/components/Image'

Vue.use(VueAxios)

Vue.use(Image)
Vue.component('page-view', PageView)
Vue.component('card-box', CardBox)
Vue.component('select-dict', SelectDict)
Vue.component('int-input', InputNumber)
Vue.component('Empty', Empty)
/**
 * 定义一个get方法供template中调用，这样可以避免object元素不存在时报Cannot read property 'type' of undefined
 * @param {*} obj vue实例的对象
 * @param {*} exp 属性表代式 支持多级 one.two.three
 * 使用方法 {{get(data, 'name')}}
 */
Vue.prototype.get = get
Vue.prototype.$get = get

Vue.use(ViewUI)

Vue.config.productionTip = false

const BXHIframe = window.BXHIframe
Vue.prototype.$bxhIframe = BXHIframe

router.beforeEach((to, from, next) => {
  if ((to.path.includes('AiSolution') || to.path.includes('packDesign')) && !store.getters.isLogin) {
    Message.info('请先登录')
    return next('/')
  }
  const { company, businessScope, phonenumber, userName } = store.getters.userInfo
  if (to.path.includes('OrdinaryPackage') && (!company || !businessScope || !phonenumber || !userName)) {
    Message.info('请先完善个人信息！')
    return next('/usercenter')
  }
  if (to.path.includes('HeavyPackage') && (!company || !businessScope || !phonenumber || !userName)) {
    Message.info('请先完善个人信息！')
    return next('/usercenter')
  }
  ViewUI.LoadingBar.start()
  next()
})

router.afterEach(route => {
  ViewUI.LoadingBar.finish()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
