<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <a href="/"> <img src="@/assets/images/logo.svg" alt=""></a>
      </div>
      <div class="fr header-right">
        <div class="menu">
          <ul>
            <li :class="{'is-active':$route.name === 'Home'}">
              <a href="javascript:;" @click.prevent="$router.push('/')">首页</a>
            </li>
            <li :class="{'is-active':$route.path.includes('AiSolution')}">
              <a href="javascript:;" @click.prevent="$router.push('/AiSolution')">
                <Icon type="ios-star" style="color:#FEE500;font-size:16px" /> 包装方案
              </a>
            </li>
            <li :class="{'is-active':$route.path.includes('packDesign')}">
              <a href="javascript:;" @click.prevent="$router.push('/packDesign')">纸箱绘图</a>
            </li>
            <li>
              <a href="">俱乐部</a>
            </li>
            <li :class="{'is-active':$route.path.includes('help')}">
              <a href="javascript:;" @click.prevent="$router.push('/help')">帮助中心</a>
            </li>
            <li :class="{'is-active':$route.path.includes('aboutus')}">
              <a href="javascript:;" @click.prevent="$router.push('/aboutus')">关于我们</a>
            </li>

          </ul>
        </div>
        <div class="userinfo" v-if="isLogin">
          <a href="javascript:;" @click="$router.push('/usercenter')">
            <Avatar icon="ios-person" />
            {{userInfo.userName}}
          </a>

          <Button style="margin-left: 20px;" @click="logout">
            <Icon type="md-log-out" /> 退出
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLogin', 'userInfo'])
  },
  methods: {
    logout () {
      this.$Modal.confirm({
        title: '提示',
        content: '确认注销账号?',
        onOk: () => {
          this.$store.dispatch('LOGOUT').then(res => {
            this.$router.push('/')
          })
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.userinfo {
    color: #255c7d !important;
    font-size: 14px;
    a {
        color: #255c7d !important;
    }
}
</style>
