import Vue from 'vue'
import moment from 'moment'
import store from '../store'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return dataStr && moment(dataStr).format(pattern)
})

Vue.filter('formatTel', (tel) => {
  return tel && tel.replace(/^(.{3})(.*)(.{4})$/, '$1 **** $3')
})

Vue.filter('dict', (val, type) => {
  try {
    if (!type) return '-'
    if (isNaN(val)) return val
    const attributeMap = store.state.PackageAttribute
    const dict = attributeMap[type]
    return dict && dict.find(e => e.attributeValue === val + '').attributeLabel
  } catch (error) {
    return '-'
  }
})

Vue.filter('truncate', function (value, length) {
  if (!value) return ''
  value = value.toString()
  if (value.length > length) {
    return value.substring(0, length) + '...'
  } else {
    return value
  }
})
