import http from '@/utils/request'

export const registerUser = data => {
  return http.post('dist/register', data)
}

export const passwordLogin = data => {
  return http.post('dist/loginByPassword', data)
}

export const loginByCode = data => {
  return http.post('dist//loginByCode', data)
}

export const logout = data => {
  return http.post('/auth/logout', data)
}

export const getUserInfo = () => {
  return http.get('dist/getUserInfo')
}

export const sendSms = data => {
  return http.post('api/registerSms/sendSms', data)
}

export const updateUserInfo = data => {
  return http.post('dist/updateUserInfo', data)
}

export const validataPwd = data => {
  return http.post('dist/validataPwd', data)
}

export const resetPwdByOldPwd = data => {
  return http.post('dist/resetPwdByOldPwd', data)
}

export const validataCode = data => {
  return http.post('dist/validataCode', data)
}

export const updateMobile = data => {
  return http.post('dist/updateMobile', data)
}

export const resetPwd = data => {
  return http.post('dist/resetPwd', data)
}
