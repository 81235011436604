import axios from 'axios'
import store from '@/store'
import { Message } from 'view-design'
import { VueAxios } from './axios'
import { throttle } from 'lodash'
import qs from 'qs'
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL : window.BASE_URL, // api base_url
  timeout: 60000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    // 'token': store.state.token
  },
  withCredentials: true,
  responseType: 'json'
})

const errorHandler = throttle(function (result = {}) {
  const { message } = result
  Message.error({
    content: message
  })
  // 白名单 不处理
}, 2000)

const err = error => {
  if (error.response) {
    const data = error.response.data
    const token = store.state.token
    if (error.response.status === 403) {
      errorHandler({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 404) {
      errorHandler({
        message: 'Not Found',
        description: data.message
      })
    }
    if (error.response.status === 401) {
      errorHandler({
        message: '登录超时',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = store.state.token
  if (token) {
    config.headers.token = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  if (
    config.method === 'post' ||
    config.method === 'put' ||
    config.method === 'delete'
  ) {
    // 序列化
    config.data = qs.stringify(config.data, { allowDots: true })
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use(response => {
  console.log('response', response.config.responseType)
  const { code, msg } = response.data
  const isSuccess = code === 0
  if (code === 500 && msg === 'token is invalid') {
    errorHandler({
      message: '登录超时',
      description: 'Authorization verification failed'
    })
    store.dispatch('LOGOUT').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }
  return { ...response.data, isSuccess }
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
export default service
