<template>
  <div class="card-box">
    <div class="card-title">
      <span>{{title}}</span>
      <div class="card-title__right">
        <slot name="extral"></slot>
      </div>
    </div>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
    padding: 20px 40px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e1e1e1;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #333333;
        &__right {
        }
    }
    .card-content {
        padding: 0;
    }
}
</style>
