<template>
  <Select v-model="dictValue" :placeholder="placeholder" @on-change="onChange" clearable>
    <Option v-for="item in attrList" :key="item.attributeValue" :value="item.attributeValue">{{item.attributeLabel}}</Option>
  </Select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    type: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      dictValue: ''
    }
  },
  watch: {
    value: function (newval) {
      this.dictValue = newval + ''
    }
  },
  computed: {
    ...mapGetters(['PackageAttribute']),
    attrList () {
      return this.$get(this.PackageAttribute, this.type, [])
    }
  },
  created () {
    if (this.defaultValue) this.dictValue = this.defaultValue + ''
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
