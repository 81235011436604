import http from '@/utils/request'

export const getPackageAttributeDropBoxs = () => {
  return http.get('dist/getPackageAttributeDropBoxs')
}

export const getCompanyBusinessScope = () => {
  return http.get('dist/getCompanyBusinessScope')
}

export const createProductArrangement = data => {
  return http.post('dist/heavyProductArrangement', data)
}

export const createNormalProductArrangement = data => {
  return http.post('dist/normalProductArrangement', data)
}

export const createDraftProductArrangement = data => {
  return http.post('dist/draftProductArrangement', data)
}

export const getUserPackageHistoryRecordsAndPage = data => {
  return http.get('dist/getUserPackageHistoryRecordsAndPage', { params: data })
}

export const draftList = data => {
  return http.post('dist/draft/list', data)
}

export const getPackageTypeCountById = data => {
  return http.get('dist/getPackageTypeCountById', { params: data })
}

export const getPackageTypeCountByIdAndStatus = data => {
  return http.get('dist/getPackageTypeCountByIdAndStatus', { params: data })
}

export const getPlanInfo = params => {
  return http.get('dist/loadingPlan', { params })
}

export const getPackageInfo = id => {
  return http.get('dist/selectPackageInfo', { params: { packageinfoId: id } })
}

export const deletePackageInfo = id => {
  return http.delete('dist/deletePackageInfo', {
    params: { packageinfoIds: id }
  })
}

export const updateHeavyPackageInfo = data => {
  return http.put('dist/updateHeavyPackageInfo', data)
}

export const updateNormalPackageInfo = data => {
  return http.put('dist/updateNormalPackageInfo', data)
}

export const getBoxTypeList = params => {
  return http.post('dist/boxType/list', {}, { params })
}

export const getHeavyMaterialList = params => {
  return http.post('dist/heavyMaterial/list', {}, { params })
}

export const listPalletBomInfo = params => {
  return http.post('dist/listPalletBomInfo', {}, { params })
}
export const delPalletBomInfo = data => {
  return http.post('dist/delPalletBomInfo', data)
}
export const addPalletBomInfo = data => {
  return http.post('dist/addPalletBomInfo', data)
}
export const generateCAD = params => {
  return http.get('dist/generateCAD', { params })
}

export const updateCartonSize = data => {
  return http.post('dist/updateCartonSize', data)
}

export const getSuggestionPx = data => {
  return http.post('dist/getSuggestionPx', data)
}

export const getCustomMaterial = () => {
  return http.post('dist/customMaterial/list')
}

export const getDocument = (data) => {
  return http.post('dist/document/list', data)
}

export const getDocumentCate = (data) => {
  return http.post('dist/document/parent/list', data)
}

export const getDocumentInfo = (id) => {
  return http.post('dist/document/updateRead/' + id)
}

export const userAuth = (data) => {
  return http.post('dist/userAuth/add', data, {
    headers: {
      'Content-Type': 'multipart/form-data,boundary=' + new Date().getTime()
    }
  })
}

export const getAuthInfo = (id) => {
  return http.get('dist/userAuth/get')
}

// 生成CAD图
export const generateCadByBox = (params) => {
  return http.get('dist/generateCadByBox', { params })
}

// 获取支持设计箱型列表
export const getBoxTypeSupportList = (id) => {
  return http.get('dist/boxTypeSupport/list')
}

// 获取素材列表
export const getFodderList = (data) => {
  return http.get('dist/fodder/list', { params: data })
}

// 获取楞型列表
export const getLengxingrList = (id) => {
  return http.get('dist/lengxing/list')
}

// 保存设计方案草稿
export const designDraftsAdd = (data) => {
  return http.post('dist/designDrafts/add', data)
}

// 查询设计方案草稿
export const designDraftsList = (data) => {
  return http.get('dist/designDrafts/list', { params: data })
}

// 编辑设计方案草稿
export const designDraftsEdit = (data) => {
  return http.post('dist/designDrafts/edit', data)
}

// 删除方案草稿
export const designDraftsRemove = (data) => {
  return http.post('dist/designDrafts/remove', data)
}
