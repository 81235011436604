import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/components/Layout/BaseLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register/index.vue')
      },
      {
        path: '/AiSolution',
        name: 'AiSolution',
        redirect: '/AiSolution/home',
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'home',
            name: 'AiSolution',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/index.vue')
          },
          {
            path: 'OrdinaryPackage',
            name: 'OrdinaryPackage',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/OrdinaryPackage/index.vue')
          },
          {
            path: 'HeavyPackage',
            name: 'HeavyPackage',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/HeavyPackage/index.vue')
          },
          {
            path: 'Drafts',
            name: 'Drafts',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/Drafts/index.vue')
          },
          {
            path: 'PackagingRecord',
            name: 'PackagingRecord',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/PackagingRecord/index.vue')
          },
          {
            path: 'PackageInfo',
            name: 'PackageInfo',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/PackageInfo/index.vue')
          },
          {
            path: 'OrdinaryPackage/edit',
            name: 'OrdinaryPackage.edit',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/OrdinaryPackage/index.vue')
          },
          {
            path: 'HeavyPackage/edit',
            name: 'HeavyPackage.edit',
            component: () => import(/* webpackChunkName: "AiSolution" */ '../views/AiSolution/HeavyPackage/index.vue')
          }
        ]
      },
      {
        path: '/usercenter',
        name: 'usercenter',
        component: () => import(/* webpackChunkName: "usercenter" */ '../views/UserCenter/index.vue')
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "Aboutus" */ '../views/Aboutus/index.vue')
      },
      {
        path: '/help',
        name: 'help',
        redirect: '/help/list',
        component: () => import(/* webpackChunkName: "Help" */ '../views/Help/index.vue'),
        children: [
          {
            path: '/help/list',
            name: 'help.list',
            component: () => import(/* webpackChunkName: "Help" */ '../views/Help/list.vue')
          },
          {
            path: '/help/detail/:id',
            name: 'help.detail',
            component: () => import(/* webpackChunkName: "Help" */ '../views/Help/detail.vue')
          }
        ]
      },
      {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
      },
      {
        path: '/packDesign',
        name: 'packDesign',
        component: () => import(/* webpackChunkName: "Aboutus" */ '../views/PackDesign/index.vue')
      }
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
