<template>
  <div class="footer">
    <div class="container">
      <div class="footer-con">
        <div class="footer-nav">
          <div>
            <p>优纳伯</p>
            <p>关于我们</p>
            <p>反馈问题</p>
          </div>
          <div class="contact">
            <p>地址：东莞市万江街区南城街道波普中心603</p>
            <p>联系电话：13268550017</p>
            <p>邮箱：zhangzhaorong050@163.com</p>
          </div>
        </div>
        <div class="qrcode">
          <img src="@/assets/images/qrcode.png" alt="">
          <p>扫一扫关注我们</p>
        </div>
      </div>

      <div class="copyright">Copyright 2020-{{new Date().getFullYear()}} PackingGo. All Rights Reserved. 判可狗 版权所有</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>
