<template>
  <Input type="number" v-model="inputValue" @on-keyup="btKeyUp" v-bind="$attrs" v-on="$listeners">
  <span v-if="unit" slot="suffix" class="unit">{{unit}}</span>
  </Input>
</template>

<script>
export default {
  props: {
    defaultValue: {
      type: [String, Number],
      defalut: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  watch: {
    value: function (newval) {
      this.inputValue = newval
    }
  },
  created () {
    if (this.defaultValue !== '') this.inputValue = this.defaultValue
  },
  methods: {
    btKeyUp () {
      // 不允许输入特殊符号
      this.inputValue = this.inputValue && this.inputValue.toString().replace(/^\.+|[^\d]/g, '')
      if (this.maxlength) {
        this.inputValue = this.inputValue.slice(0, this.maxlength)
      }
      if (this.min && this.inputValue) {
        this.inputValue = Math.max(this.inputValue, this.min)
      }
      if (this.max && this.inputValue) {
        this.inputValue = Math.min(this.inputValue, this.max)
      }
      this.$emit('input', this.inputValue)
      this.$emit('change', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-input-suffix {
    right: 10px;
}
/deep/ .unit {
    display: block;
    text-align: right;
    line-height: 30px;
    font-size: 12px;
    // color: #999999;
}
</style>
