<template>
  <div>
    <AppHeader></AppHeader>
    <div class="main-cantainer">
      <router-view></router-view>
    </div>
    <AppFooter v-if="$route.name === 'Home' || $route.name === 'aboutus'"></AppFooter>
    <Kefu></Kefu>
  </div>
</template>

<script>
import AppHeader from '@/components/Header'
import AppFooter from '@/components/Footer'
import Kefu from './Kefu'
export default {
  components: {
    AppHeader,
    AppFooter,
    Kefu
  }
}
</script>

<style lang="scss" scoped>
.main-cantainer {
    min-height: calc(100vh - 65px);
}
</style>
