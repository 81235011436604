import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import { passwordLogin, getUserInfo, loginByCode } from '@/api/user'
import { getPackageAttributeDropBoxs } from '@/api/packing'
import { Message } from 'view-design'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    OrdinaryPackage: {},
    HeavyPackage: {},
    token: '',
    userInfo: {},
    PackageAttribute: {},
    packageInfo: {}
  },
  getters,
  mutations: {
    SetOrdinaryPackage: (state, payload) => {
      state.OrdinaryPackage = payload
    },
    SetHeavyPackage: (state, payload) => {
      state.HeavyPackage = payload
    },
    SetToken: (state, token) => {
      state.token = token
    },
    SetUserInfo: (state, data) => {
      state.userInfo = data
    },
    SetPackageAttribute: (state, data) => {
      state.PackageAttribute = data
    },
    SetPackageInfo: (state, data) => {
      state.packageInfo = data
    }
  },
  actions: {
    saveOrdinaryPackage ({ commit }, payload) {
      commit('SetOrdinaryPackage', payload)
    },
    saveHeavyPackage ({ commit }, payload) {
      commit('SetHeavyPackage', payload)
    },
    async PASSWORD_LOGIN ({ commit, dispatch }, payload) {
      const res = await passwordLogin(payload)
      if (res.isSuccess) {
        const { token } = res.data
        commit('SetToken', token)
        await dispatch('GET_USERINFO')
        return Promise.resolve(res)
      } else {
        Message.error(res.msg)
        return Promise.reject(res)
      }
    },
    async CODE_LOGIN ({ commit, dispatch }, payload) {
      const res = await loginByCode(payload)
      if (res.isSuccess) {
        const { token } = res.data
        commit('SetToken', token)
        await dispatch('GET_USERINFO')
        return Promise.resolve(res)
      } else {
        Message.error(res.msg)
        return Promise.reject(res)
      }
    },
    async GET_USERINFO ({ commit }, payload) {
      const res = await getUserInfo()
      if (res.isSuccess) {
        commit('SetUserInfo', res.data)
      }
    },
    async LOGOUT ({ commit }, payload) {
      commit('SetToken', '')
      commit('SetUserInfo', {})
      commit('SetPackageAttribute', {})
      commit('SetOrdinaryPackage', {})
      return Promise.resolve()
    },
    async GET_PACKAGEATTR ({ commit }) {
      const res = await getPackageAttributeDropBoxs()
      if (res.isSuccess) {
        commit('SetPackageAttribute', res.data)
      }
    }
  },
  modules: {
  },
  // state持久化
  plugins: [
    createPersistedState({
      // 指定需要持久化保存的state
      // storage: window.sessionStorage,
      // storage: {
      //     getItem: key => Cookies.get(key),
      //     // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
      //     removeItem: key => Cookies.remove(key)
      // },
      key: 'PackingGo',
      paths: ['token', 'userInfo', 'PackageAttribute', 'OrdinaryPackage', 'HeavyPackage', 'packageInfo']
    })
  ]
})
