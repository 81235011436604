<template>
  <div class="empty">
    <img src="@/assets/images/empty.svg" width="200" alt="">
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<style lang="scss" scoped>
.empty {
    width: 400px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin-top: 20px;
    }
}
</style>
