<template>
  <div class="page-view">
    <div v-if="title" class="page-title">
      <span>{{title}}</span>
      <div class="page-title__right">
        <slot name="extral"></slot>
      </div>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.page-view {
    width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    .page-title {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #67c5ec;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.02em;
        font-weight: 500;
        color: #333333;
    }
    .page-content {
        padding: 20px 0;
    }
}
</style>
