<template>
  <div class="kefu">
    <Tooltip content="电话：400 8333 7371" placement="left" theme="light">
      <div class="kefu-item">
        <Icon type="ios-call" size="36" />
        <p>电话咨询</p>
      </div>
    </Tooltip>

    <div class="kefu-item">
      <Icon type="ios-chatbubbles" size="30" />
      <p>在线咨询</p>
    </div>
    <Tooltip placement="left" theme="light">
      <div slot="content">
        <img src="@/assets/images/wechat.jpg" width="100px" alt="">
      </div>
      <div class="kefu-item">
        <img src="@/assets/images/wechat.svg" alt="">
        <!-- <Icon type="ios-call" size="30" /> -->
        <p>微信咨询</p>
      </div>
    </Tooltip>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.kefu {
    position: fixed;
    top: 50%;
    margin-top: -100px;
    right: 10px;
    width: 80px;
    .kefu-item {
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;
        text-align: center;
        background: rgba(240, 249, 253, 0.8);
        color: #67c5ec;
        margin-bottom: 10px;
        cursor: pointer;
    }
}
</style>
